import {
  IconArrowNarrowRight,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import React, { useState } from "react";

const PlanDetails = () => {
  const [activeTab, setActiveTab] = useState("");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <>
      <div className="container d-none">
        <div className="d-flex justify-content-center">
          <ul
            className="nav nav-pills nav-tabs nav-fill  p-1 mt-5 small bg-primary rounded-5 shadow-sm"
            id="pillNav2"
            role="tablist"
            style={{
              "--bs-nav-link-color": "rgba(255, 255, 255, .75)",
              "--bs-nav-link-hover-color": "#fff",
              "--bs-nav-pills-link-active-color": "black",
              "--bs-nav-pills-link-active-bg": "#fff",
              gap: "8px",
            }}
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link rounded-5 ${
                  activeTab === "home" ? "active" : ""
                }`}
                id="home-tab2"
                type="button"
                role="tab"
                aria-selected={activeTab === "home"}
                onClick={() => handleTabClick("home")}
              >
                Plan Details
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link rounded-5 ${
                  activeTab === "profile" ? "active" : ""
                }`}
                id="profile-tab2"
                type="button"
                role="tab"
                aria-selected={activeTab === "profile"}
                onClick={() => handleTabClick("profile")}
              >
                Payment Methods
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link rounded-5 ${
                  activeTab === "contact" ? "active" : ""
                }`}
                id="contact-tab2"
                type="button"
                role="tab"
                aria-selected={activeTab === "contact"}
                onClick={() => handleTabClick("contact")}
              >
                Invoice List
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content p-3" id="pillNav2Content">
          <div
            className={`tab-pane fade ${
              activeTab === "home" ? "show active" : ""
            }`}
            id="home-content"
            role="tabpanel"
          >
            {/* <p>
                You can track invited candidates,analyze their results and make
                informed hiring decisions in minutes.
              </p> */}

            <div className="pricing-container container  ">
              <div className="pst-space row  justify-content-center ">
                <div
                  className="price_section col-lg-4 mb-xs-3 mb-sm-4 mb-lg-0"
                  style={{ margin: "0" }}
                >
                  <div className="pricing-card">
                    <h3 style={{ fontWeight: "500" }}>Basic Plan</h3>
                    <div className="price">
                      <span className="currency fw-bold">₹</span>
                      <span className="amount">10,000</span>
                      <span className="time">/ month</span>
                    </div>
                    <p className="heading fs-5 mt-1">Featured Included:</p>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center ">
                      <div className="wrap">
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>{" "}
                      <p className="pricing-features-p fs-5 mt-3">
                        All Question types except video
                      </p>
                    </div>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Unlimited Test Creations
                      </p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Candidates - 300 test submissions/month
                      </p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Random Image Captures
                      </p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3"> Users 5</p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Data Retension 1 Year
                      </p>
                    </div>

                    <a
                      href="#"
                      className="btn btn-primary w-100 mt-3 fs-5 fw-bold"
                    >
                      Downgrade to Basic
                    </a>
                  </div>
                </div>
                <div
                  className="price_section col-lg-4  mb-xs-3 mb-sm-4 mb-lg-0"
                  style={{ margin: "0" }}
                >
                  <div className="pricing-card-popular">
                    <div className="pricing-popular-badge">Selected</div>
                    <h3>Standard Plan</h3>
                    <div className="price">
                      <span className="currency fw-bold">₹</span>
                      <span className="amount">10,000</span>
                      <span className="time">/ month</span>
                    </div>
                    <p className="heading fs-5 mt-1">Featured Included:</p>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="white"
                          size={35}
                          stroke={2}
                        />
                      </div>
                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Video Feature
                      </p>
                    </div>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        <IconCircleCheckFilled
                          color="white"
                          size={35}
                          stroke={2}
                        />
                      </div>
                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Unlimited Users
                      </p>
                    </div>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="white"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        2000 Candidates/month
                      </p>
                    </div>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="white"
                          size={35}
                          stroke={2}
                        />
                      </div>
                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Question Repository Access
                      </p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="white"
                          size={35}
                          stroke={2}
                        />
                      </div>
                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Data Retension 3 Years
                      </p>
                    </div>

                    <a
                      href="#"
                      className="btn btn-light mt-3 w-100 fw-bold fs-5"
                    >
                      Currently Active
                    </a>
                  </div>
                </div>
                <div className="price_section col-lg-4" style={{ margin: "0" }}>
                  <div className="pricing-card">
                    <h3>Enterprise Plan</h3>
                    <div className="price">
                      <span className="currency fw-bold">₹</span>
                      <span className="amount">10,000</span>
                      <span className="time">/ month</span>
                    </div>

                    <p className="heading fs-5 mt-1">Featured Included:</p>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p  fs-5 mt-3">
                        {" "}
                        Contact Support
                      </p>
                    </div>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>{" "}
                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Video Feature
                      </p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        {" "}
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>{" "}
                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Unlimited Users
                      </p>
                    </div>

                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>

                      <p className="pricing-features-p fs-5 mt-3">
                        {" "}
                        Unlimited Candidates/month
                      </p>
                    </div>
                    <div className="pricing-icons-section d-flex gap-2 align-items-center">
                      <div className="wrap" style={{ width: "35px" }}>
                        <IconCircleCheckFilled
                          color="#0d83fd"
                          size={35}
                          stroke={2}
                        />
                      </div>
                      <p className="pricing-features-p fs-5 mt-3">
                        Question Repository Access
                      </p>
                    </div>

                    <a
                      href="#"
                      className="btn btn-primary mt-3 w-100 fw-bold fs-5"
                    >
                      Upgrade to Enterprise
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`tab-pane fade ${
              activeTab === "profile" ? "show active" : ""
            }`}
            id="profile-content"
            role="tabpanel"
          >
            <div className="row ">
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <p className="fs-5">
                  You can add your skill-based questions as much as you want and
                  create thousand of test modules.
                </p>
              </div>
              <div className="col-md-6 mt-5 ps-3 ">
                <img
                  src="https://themewagon.github.io/iLanding/assets/img/features-illustration-2.webp"
                  alt=""
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              activeTab === "contact" ? "show active" : ""
            }`}
            id="contact-content"
            role="tabpanel"
          >
            <div className="row">
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <p className="fs-5">
                  It automatically sends an exam link to a candidate.So,you dont
                  have to worry about informing your candidates.
                </p>
              </div>
              <div className="col-md-6 mt-5 ps-3">
                <img
                  src="https://themewagon.github.io/iLanding/assets/img/features-illustration-3.webp"
                  alt=""
                  height={"90%"}
                  width={"90%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanDetails;
