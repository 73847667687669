import React, { useRef, useState, useEffect } from "react";
import "./AudioVideoTest.css"; // Separate CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faPause } from "@fortawesome/free-solid-svg-icons";

const AudioVideoTest = () => {
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const [stream, setStream] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [micStatus, setMicStatus] = useState(null);
  const [permissionError, setPermissionError] = useState(false);
  const [recordingIndicator, setRecordingIndicator] = useState(false); // For displaying recording status
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const canvasRef = useRef(null);
  const requestAnimationFrameId = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Fetch media stream (video + audio) and handle permission denial
  useEffect(() => {
    const getStream = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
        setStream(mediaStream);
        setPermissionError(false); // Reset error if permission is granted
      } catch (error) {
        console.error("Error accessing media devices.", error);
        setPermissionError(true); // Set error if permission is denied
      }
    };

    getStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  // Start recording

  const startRecording = async () => {
    try {
      // Get the user's audio stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Initialize MediaRecorder
      mediaRecorderRef.current = new MediaRecorder(stream);

      // Start recording and visualizing the audio
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        audioChunksRef.current = [];
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing audio stream:", err);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    if (requestAnimationFrameId.current) {
      cancelAnimationFrame(requestAnimationFrameId.current);
    }
  };

  useEffect(() => {
    // Ensure the canvas size is set when the component mounts
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = 500; // Adjust canvas width
      canvas.height = 150; // Adjust canvas height
    }
  }, []);

  const initializeAudio = async () => {
    try {
      // Get the user's audio stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Create an AudioContext and AnalyserNode
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 256;

      // Create a media stream source node
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);

      // Ensure the AudioContext is resumed if needed
      if (audioContextRef.current.state === "suspended") {
        audioContextRef.current.resume();
      }

      // Start drawing the waveform
      drawWaveform();
    } catch (err) {
      console.error("Error accessing audio stream:", err);
    }
  };

  const drawWaveform = () => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Ensure canvas is available

    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) return; // Ensure canvas context is available

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    // Create a buffer to hold frequency data
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Draw function that will be called repeatedly
    const draw = () => {
      analyserRef.current.getByteFrequencyData(dataArray);

      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT); // Clear the canvas

      const barWidth = WIDTH / bufferLength;
      let barHeight;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];

        // Set the color for the bars based on frequency data
        canvasCtx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;

        // Draw the bars on the canvas
        canvasCtx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

        x += barWidth + 1;
      }

      requestAnimationFrameId.current = requestAnimationFrame(draw); // Loop the drawing
    };

    draw(); // Start drawing the waveform
  };

  useEffect(() => {
    // Set up canvas size
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = 500;
      canvas.height = 150;
    }
  }, []);

  // Check if the microphone is working
  const checkMicStatus = async () => {
    setAudioUrl(null);
    startRecording();
    setTimeout(() => {
      stopRecording();
    }, 10000);
    initializeAudio();
    setIsInitialized(true);
    try {
      const testStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      const audioTrack = testStream.getAudioTracks()[0];
      if (audioTrack) {
        setMicStatus("Microphone is working!");
      } else {
        setMicStatus("Microphone not detected!");
      }
      testStream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      setMicStatus("Error accessing microphone!");
      console.error("Error accessing microphone:", error);
    }
  };

  // Guide the user to enable permissions in their browser
  const handleEnablePermissions = () => {
    const browser = navigator.userAgent.toLowerCase();
    if (browser.indexOf("chrome") !== -1) {
      // Chrome
      alert(
        "To enable permissions, go to chrome://settings/content/camera and microphone settings."
      );
    } else if (browser.indexOf("firefox") !== -1) {
      // Firefox
      alert(
        "To enable permissions, go to about:preferences#privacy, then scroll to the Camera section."
      );
    } else if (browser.indexOf("safari") !== -1) {
      // Safari
      alert(
        "To enable permissions, go to Safari preferences > Websites > Camera/Microphone."
      );
    } else {
      // Default message for unsupported browsers
      alert(
        "Please enable camera and microphone permissions in your browser settings."
      );
    }
  };

  return (
    <div className="container test-container">
      <div className="row">
        <div className="col-12">
          <h1 className="mb-5">Audio and Video Test</h1>

          {/* Video Display Section */}
          <div className="video-section">
            <h2>Video Preview</h2>
            {permissionError ? (
              <div className="error-message">
                <h5>
                  Microphone/Camera access is not granted. Please enable
                  permissions.
                </h5>
                <button className="button m-0" onClick={handleEnablePermissions}>
                  Enable Permissions
                </button>
              </div>
            ) : (
              <video ref={videoRef} autoPlay className="video" width={400} />
            )}
          </div>

          {/* Microphone Status Section */}
          <div className="mic-status-section">
            <h2>Microphone Check</h2>
            <button
              className="button m-0 me-3"
              onClick={checkMicStatus}
              disabled={permissionError}
            >
              {!isInitialized ? "Check Microphone" : "Check Again"}
            </button>
          </div>

          {/* Audio Player for Playback */}
          {isInitialized && !audioUrl && (
            <div
              style={{
                padding: "50px",
                backgroundColor: "#000",
                marginTop: "20px",
                color: "white",
              }}
            >
              <h4 className="text-center mb-3">Say Something !</h4>
              <canvas
                ref={canvasRef}
                style={{
                  border: "1px solid black",
                  backgroundColor: "#f0f0f0",
                  width: "100%",
                  height: "70px",
                }}
              />
            </div>
          )}
        </div>
        {audioUrl && (
          <div>
            <audio
              controls
              autoPlay
              src={audioUrl}
              style={{ width: "100%" }}
            ></audio>
          </div>
        )}
      </div>
      <SoundTest />
    </div>
  );
};

export default AudioVideoTest;

const SoundTest = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5); // Volume range from 0 to 1
  const soundRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      soundRef.current.pause();
    } else {
      soundRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setVolume(newVolume);
    if (soundRef.current) {
      soundRef.current.volume = newVolume;
    }
  };

  return (
    <div className="mt-4">
      <h2>Sound Check</h2>
      <div style={{ margin: "20px 0" }}>
        <button
          className="button m-0"
          onClick={handlePlayPause}
          style={{ padding: "10px 20px", fontSize: "16px" }}
        >
          {isPlaying ? "Pause" : "Test Audio"}
          {isPlaying ? <FontAwesomeIcon icon={faPause} className="ms-3" /> : <FontAwesomeIcon icon={faPlay} className="ms-3"/>}
        </button>
      </div>
      <audio
        ref={soundRef}
        loop
        src="/sound-track.mp3"
        preload="auto"
        style={{ width: "100%" }}
      ></audio>
    </div>
  );
};
