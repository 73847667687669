import {
  IconBrandChrome,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconCircleCheckFilled,
  IconClipboardText,
} from "@tabler/icons-react";
import { IconBrandYoutube } from "@tabler/icons-react";
import { IconCircleCheck } from "@tabler/icons-react";
import { IconMoodSmile } from "@tabler/icons-react";
import { IconShieldCheck } from "@tabler/icons-react";
import { IconActivity } from "@tabler/icons-react";
import { IconBriefcase2 } from "@tabler/icons-react";
import { IconCircuitBattery } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import { IconBrandGoogleMaps } from "@tabler/icons-react";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { IconPhone } from "@tabler/icons-react";
import { IconMail } from "@tabler/icons-react";
import { IconBrandX } from "@tabler/icons-react";
import { IconBaselineDensityMedium } from "@tabler/icons-react";

import "./Home.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const [activeTab, setActiveTab] = useState("contact"); // State for the active tab
  // Function to handle tab switching
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const scrollToPricing = (e) => {
    e.preventDefault();
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const scrollToAboutus = (e) => {
    e.preventDefault();
    const aboutusSection = document.getElementById("aboutus");
    if (aboutusSection) {
      aboutusSection.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="d-none">
      <nav
        className="navbar navbar-expand-lg fixed-top "
        style={{
          padding: 0,
          justifyContent: "center",
          backgroundColor: "#fbfbfb",
        }}
      >
        <div
          className="container py-0 px-sm-0"
          style={{ position: "relative" }}
        >
          <a className="navbar-brand" href="#" style={{ margin: "0" }}>
            <img
              src="https://www.hrmware.com/images/logo-header.png"
              alt="logo"
              className="logo-img"
            />
          </a>
          {/* Nvabar toggler icon for mobile view */}
          <button
            className="navbar-toggler ml-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto pe-3">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#pricing"
                  onClick={scrollToPricing}
                >
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  href="#aboutus"
                  onClick={scrollToAboutus}
                >
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" style={{cursor:"pointer"}} onClick={() => navigate("/login")}>
                  Login
                </a>
              </li>
              <li className="nav-item">
                {" "}
                <button
                  className="btn mob-btn "
                  style={{ margin: "0" }}
                >
                  Register
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section className="hero1_section " style={{ marginTop: "130px" }}>
        <div className="container">
          <div id="hero1" className="hero1-section">
            <div className="content-section container p-0">
              <div className="row align-items-center ">
                <div className="text_section col-md-6">
                  <h1 className="company-badge mb-4 fs-5">Online Skill Test</h1>
                  <h2 className="mt-5 fs-1">Hire Top Talents In Minutes</h2>
                  <p className="mt-5">
                    Finding the right talent for your organization is a
                    challenge.It becomes easy if you simplifying hiring process
                    and shortlist candidates in less time.Our online skill
                    tester is designed to help you do just that-seamlessly and
                    efficient.
                  </p>
                  <div className="buttons d-flex mt-5 gap-3">
                    <button className="btn btn-primary custom-btn1">
                      Create Tests For Free
                    </button>
                    <button className="btn btn-secondary1 custom-btn2">
                      <IconBrandYoutube stroke={1} /> Watch Demo
                    </button>
                  </div>
                </div>
                <div className="image_section col-md-6 d-flex justify-content-center align-items-center gap:5px ;">
                  <img
                    src="https://themewagon.github.io/iLanding/assets/img/features-illustration-1.webp
                  "
                    alt="ilt"
                    height={"90%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus" id="aboutus">
        <div className="aboutus container" style={{ marginTop: "130px" }}>
          <div className="row d-flex ">
            <div
              className="col-xl-6 col-md-12"
              style={{ paddingRight: "34px" }}
            >
              <h5 style={{ color: "blue" }}> About Us</h5>
              <h5 className="mt-4">
                Shortlisting can be tedious,right?Thats's where online skill
                tests step in.
              </h5>
              <p className="mt-4">
                In today's compeititive world ,finding the best talents is'nt
                about just scanning resumes;it's about driving deeper into
                candidate skills. Our skill tester bridges this gap ,helping
                organizations identify the perfect hires with precision and
                ease.It allows employer to objectively evaluate
                candidates,ensuring every hire align with organizational goals.
              </p>
              <div className="row-feature mt-5">
                <div className="row">
                  <div className="col-12">
                    <div className="icon-items-section d-flex gap-2">
                      <div className="wrap1" style={{ width: "25px" }}>
                        {" "}
                        <IconCircleCheckFilled color="#0d83fd" stroke={1} />
                      </div>
                      <p>
                        <span className="fw-bold">Streamlined Hiring: </span>
                        Eliminate guesswork and focus on data-driven
                        recruitment.
                      </p>
                    </div>
                    <div className="icon-items-section d-flex gap-2">
                      <div className="wrap1" style={{ width: "25px" }}>
                        {" "}
                        <IconCircleCheckFilled color="#0d83fd" stroke={1} />
                      </div>
                      <p>
                        <span className="fw-bold">Objective Assessments:</span>{" "}
                        Evaluate real skills instead of relying solely on
                        resumes.
                      </p>
                    </div>
                    <div className="icon-items-section d-flex gap-2">
                      <div className="wrap1" style={{ width: "25px" }}>
                        <IconCircleCheckFilled color="#0d83fd" stroke={1} />
                      </div>

                      <p>
                        <span className="fw-bold">
                          Accurate Hiring Decisions:
                        </span>{" "}
                        Reduce bias and improve hiring accuracy.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=" col-xl-6 col-md-12 "
              style={{ paddingLeft: "15px" }}
            >
              <div className="image-wrapper ">
                <div className="images position-relative">
                  <img
                    src="https://themewagon.github.io/iLanding/assets/img/about-5.webp"
                    alt="Business Meeting"
                    className="img-fluid main-image rounded-4"
                  />
                  <img
                    src="https://themewagon.github.io/iLanding/assets/img/about-2.webp"
                    alt="Team Discussion"
                    className="img-fluid small-image rounded-4"
                  />
                </div>
                <div className="experience-badge floating">
                  <h4 className="fw-bold">
                    15<span className="fs-6"> +Years</span>
                  </h4>
                  <p className="text-white">
                    Of experience in business service
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero2_section" style={{ marginTop: "130px" }}>
        <div className="container section-title" style={{ marginTop: "130px" }}>
          <h2 className="text-center features" style={{ lineHeight: "40px" }}>
            Features
          </h2>
        </div>

        <div className="container ">
          <div className="d-flex justify-content-center">
            <ul
              className="nav nav-pills nav-tabs nav-fill  p-1 mt-5 small bg-primary rounded-5 shadow-sm"
              id="pillNav2"
              role="tablist"
              style={{
                "--bs-nav-link-color": "rgba(255, 255, 255, .75)",
                "--bs-nav-link-hover-color": "#fff",
                "--bs-nav-pills-link-active-color": "black",
                "--bs-nav-pills-link-active-bg": "#fff",
              }}
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link rounded-5 ${
                    activeTab === "home" ? "active" : ""
                  }`}
                  id="home-tab2"
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "home"}
                  onClick={() => handleTabClick("home")}
                >
                  Detailed Dashboard
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link rounded-5 ${
                    activeTab === "profile" ? "active" : ""
                  }`}
                  id="profile-tab2"
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "profile"}
                  onClick={() => handleTabClick("profile")}
                >
                  Unlimited Test Module Generation
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link rounded-5 ${
                    activeTab === "contact" ? "active" : ""
                  }`}
                  id="contact-tab2"
                  type="button"
                  role="tab"
                  aria-selected={activeTab === "contact"}
                  onClick={() => handleTabClick("contact")}
                >
                  Automated Invitation
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content p-3" id="pillNav2Content">
            <div
              className={`tab-pane fade ${
                activeTab === "home" ? "show active" : ""
              }`}
              id="home-content"
              role="tabpanel"
            >
              {/* <p>
                You can track invited candidates,analyze their results and make
                informed hiring decisions in minutes.
              </p> */}

              <div className="row">
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <p className="fs-5">
                    You can track invited candidates,analyze their results and
                    make informed hiring decisions in minutes.
                  </p>
                </div>
                <div className="col-md-6 mt-5 ps-3 ">
                  <img
                    src="https://themewagon.github.io/iLanding/assets/img/features-illustration-1.webp"
                    alt=""
                    height={"90%"}
                    width={"90%"}
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "profile" ? "show active" : ""
              }`}
              id="profile-content"
              role="tabpanel"
            >
              <div className="row ">
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <p className="fs-5">
                    You can add your skill-based questions as much as you want
                    and create thousand of test modules.
                  </p>
                </div>
                <div className="col-md-6 mt-5 ps-3 ">
                  <img
                    src="https://themewagon.github.io/iLanding/assets/img/features-illustration-2.webp"
                    alt=""
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "contact" ? "show active" : ""
              }`}
              id="contact-content"
              role="tabpanel"
            >
              <div className="row">
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <p className="fs-5">
                    It automatically sends an exam link to a candidate.So,you
                    dont have to worry about informing your candidates.
                  </p>
                </div>
                <div className="col-md-6 mt-5 ps-3">
                  <img
                    src="https://themewagon.github.io/iLanding/assets/img/features-illustration-3.webp"
                    alt=""
                    height={"90%"}
                    width={"90%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="card-section" className="features-cards section ">
        <div className="cards-container container">
          <div className="row ">
            <div
              className="col-xl-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div
                className="feature-box-orange"
                style={{ border: "2px solid yellowgreen" }}
              >
                <IconBrandChrome stroke={1} size={50} color="yellowgreen" />
                <h4 className="mt-2">Free and Online </h4>
                <p>Examine candidates at no cost, anytime and anywhere.</p>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div
                className="feature-box-blue"
                style={{ border: "2px solid orange" }}
              >
                <IconMoodSmile stroke={1} size={50} color=" orange" />
                <h4 className="mt-2">User-friendly interface</h4>
                <p>Simple,clean and easy to use,even for beginners</p>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div
                className="feature-box-green"
                style={{ border: "2px solid green" }}
              >
                <IconShieldCheck stroke={1} size={50} color="green" />
                <h4 className="mt-2">Secure and Private</h4>
                <p>
                  With advanced encryption,all information remains confidential
                  and safe
                </p>
              </div>
            </div>

            <div
              className="col-xl-3 col-md-6"
              data-aos="zoom-in"
              data-aos-delay="400"
            >
              <div
                className="feature-box-red"
                style={{ border: "2px solid red" }}
              >
                <IconCircleCheck stroke={1} size={50} color="red" />
                <h4 className="mt-2">Accessible</h4>
                <p>Run on both desktop and mobile devices.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services_section">
        <div
          className="services-container container"
          style={{ marginTop: "130px" }}
        >
          <h2 className="text-center">
            Follow These Simple Steps For Quick Selection
          </h2>
        </div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 mb-4">
              <div className="services_item">
                <div className="row">
                  <div className="col-md-2">
                    <div className="icon-wrapper">
                      <IconActivity
                        stroke={1}
                        size={"100%"}
                        className="icons"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    {" "}
                    <h6 className="fw-bold mt-3 mt-md-0">
                      Set Up Your Profile and Organization
                    </h6>
                    <p>
                      Intially,you'll create your profile and include your
                      orgnization information.After configured these two modules
                      you'll will be able to access additional modules for setup{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="services_item">
                <div className="row">
                  <div className="col-md-2">
                    <div className="icon-wrapper">
                      <IconCircuitBattery
                        stroke={1}
                        size={"100%"}
                        className="icons"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <h6 className="fw-bold mt-3 mt-md-0">
                      Add a Candidate List{" "}
                    </h6>
                    <p>
                      You will add your candidates as much as you want and
                      select tests for these candidates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="services_item">
                <div className="row">
                  <div className="col-md-2">
                    <div className="icon-wrapper ">
                      <IconBriefcase2
                        stroke={1}
                        size={"100%"}
                        className="icons "
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <h6 className="fw-bold mt-3 mt-md-0">
                      Create Tests and Test Modules
                    </h6>
                    <p>
                      You will be able to add skill-based questions one by one
                      and customize thousands of test modules for skill tests.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="services_item">
                <div className="row">
                  <div className="col-md-2">
                    <div className="icon-wrapper">
                      <IconClipboardText
                        stroke={1}
                        size={"100%"}
                        className="icons"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <h6 className="fw-bold mt-3 mt-md-0">Invite Candidates</h6>
                    <p>
                      Finally,you will have to click on the invite button,and an
                      exam invitation you will be automatically delivered to the
                      candidate.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing" id="pricing">
        <div className="container section-title">
          <h2 className="text-center ">Pricing</h2>
        </div>
        <div className="pricing-container container  ">
          <div className="pst-space row  justify-content-center ">
            <div
              className="price_section col-lg-4 mb-xs-3 mb-sm-4 mb-lg-0"
              style={{ margin: "0" }}
            >
              <div className="pricing-card">
                <h3 style={{ fontWeight: "500" }}>Free Plan</h3>
                <div className="price">
                  <span className="currency fw-bold">₹</span>
                  <span className="amount">10,000</span>
                  <span className="time">/ month</span>
                </div>
                <p className="heading fs-5 mt-1">Featured Included:</p>

                <div className="pricing-icons-section d-flex gap-2 align-items-center ">
                  <div className="wrap">
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>{" "}
                  <p className="pricing-features-p fs-5 mt-3">
                    All Question types except video
                  </p>
                </div>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Unlimited Test Creations
                  </p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Candidates - 300 test submissions/month
                  </p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Random Image Captures
                  </p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3"> Users 5</p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Data Retension 1 Year
                  </p>
                </div>

                <a href="#" className="btn btn-primary mt-3 fs-5 fw-bold">
                  Buy Now <IconArrowNarrowRight stroke={2} />
                </a>
              </div>
            </div>
            <div
              className="price_section col-lg-4  mb-xs-3 mb-sm-4 mb-lg-0"
              style={{ margin: "0" }}
            >
              <div className="pricing-card-popular">
                <div className="pricing-popular-badge">Most Popular</div>
                <h3>Standard Plan</h3>
                <div className="price">
                  <span className="currency fw-bold">₹</span>
                  <span className="amount">10,000</span>
                  <span className="time">/ month</span>
                </div>
                <p className="heading fs-5 mt-1">Featured Included:</p>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled color="white" size={35} stroke={2} />
                  </div>
                  <p className="pricing-features-p fs-5 mt-3"> Video Feature</p>
                </div>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    <IconCircleCheckFilled color="white" size={35} stroke={2} />
                  </div>
                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Unlimited Users
                  </p>
                </div>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled color="white" size={35} stroke={2} />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    2000 Candidates/month
                  </p>
                </div>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled color="white" size={35} stroke={2} />
                  </div>
                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Question Repository Access
                  </p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled color="white" size={35} stroke={2} />
                  </div>
                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Data Retension 3 Years
                  </p>
                </div>

                <a href="#" className="btn btn-light mt-3 fw-bold fs-5">
                  Buy Now
                  <IconArrowNarrowRight stroke={2} />
                </a>
              </div>
            </div>
            <div className="price_section col-lg-4" style={{ margin: "0" }}>
              <div className="pricing-card">
                <h3>Enterprise Plan</h3>
                <div className="price">
                  <span className="currency fw-bold">₹</span>
                  <span className="amount">10,000</span>
                  <span className="time">/ month</span>
                </div>
                {/* <p className="description mt-2">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium totam.
                  </p> */}
                <p className="heading fs-5 mt-1">Featured Included:</p>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p  fs-5 mt-3">
                    {" "}
                    Contact Support
                  </p>
                </div>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>{" "}
                  <p className="pricing-features-p fs-5 mt-3"> Video Feature</p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    {" "}
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>{" "}
                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Unlimited Users
                  </p>
                </div>

                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>

                  <p className="pricing-features-p fs-5 mt-3">
                    {" "}
                    Unlimited Candidates/month
                  </p>
                </div>
                <div className="pricing-icons-section d-flex gap-2 align-items-center">
                  <div className="wrap" style={{ width: "35px" }}>
                    <IconCircleCheckFilled
                      color="#0d83fd"
                      size={35}
                      stroke={2}
                    />
                  </div>
                  <p className="pricing-features-p fs-5 mt-3">
                    Question Repository Access
                  </p>
                </div>

                <a href="#" className="btn btn-primary mt-3 fw-bold fs-5">
                  Buy Now
                  <IconArrowNarrowRight stroke={2} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="faq-9 faq section light-background "
        id="faq"
        style={{ marginTop: "130px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5" data-aos="fade-up">
              <h2 className="faq_title mb-5 ">
                Have a question? Check out the FAQ
              </h2>
              <div
                className="faq-arrow d-none d-lg-block mt-3 ms-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <svg
                  className="faq-arrow"
                  width="200"
                  height="211"
                  viewBox="0 0 200 211"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "50px" }}
                >
                  <path
                    d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="faq-container  ">
                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle1"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle1" className="faq-toggle-label">
                    <h6 style={{ color: " #0d83fd", margin: "0" }}>
                      Non consectetur a erat nam at lectus urna duis?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>

                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Feugiat pretium nibh ipsum consequat. Tempus iaculis urna
                      id volutpat lacus laoreet non curabitur gravida. Venenatis
                      lectus magna fringilla urna porttitor rhoncus dolor purus
                      non.
                    </p>
                  </div>
                </div>

                {/* <div className="faq-item faq-active">
                  <h6 style={{ color: " #0d83fd" }}>
                    Non consectetur a erat nam at lectus urna duis?
                  </h6>

                  <div className="faq-content">
                    <p>
                      Feugiat pretium nibh ipsum consequat. Tempus iaculis urna
                      id volutpat lacus laoreet non curabitur gravida. Venenatis
                      lectus magna fringilla urna porttitor rhoncus dolor purus
                      non.
                    </p>
                  </div>
                  <IconChevronRight
                    stroke={1}
                    className="faq-toggle"
                    id="faq-toggle1"
                  />
                </div> */}
                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle2"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle2" className="faq-toggle-label">
                    <h6 style={{ color: " #0d83fd", margin: "0" }}>
                      Feugiat scelerisque varius morbi enim nunc faucibus?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>

                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Dolor sit amet consectetur adipiscing elit pellentesque
                      habitant morbi. Id interdum velit laoreet id donec
                      ultrices. Fringilla phasellus faucibus scelerisque
                      eleifend donec pretium. Est pellentesque elit ullamcorper
                      dignissim. Mauris ultrices eros in cursus turpis massa
                      tincidunt dui.
                    </p>
                  </div>
                </div>

                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle3"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle3" className="faq-toggle-label">
                    <h6 style={{ color: " #0d83fd", margin: "0" }}>
                      Dolor sit amet consectetur adipiscing elit pellentesque?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>
                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                      sagittis orci. Faucibus pulvinar elementum integer enim.
                      Sem nulla pharetra diam sit amet nisl suscipit. Rutrum
                      tellus pellentesque eu tincidunt. Lectus urna duis
                      convallis convallis tellus. Urna molestie at elementum eu
                      facilisis sed odio morbi quis
                    </p>
                  </div>
                </div>
                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle4"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle4" className="faq-toggle-label">
                    <h6 style={{ color: " #0d83fd", margin: "0" }}>
                      Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>

                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Dolor sit amet consectetur adipiscing elit pellentesque
                      habitant morbi. Id interdum velit laoreet id donec
                      ultrices. Fringilla phasellus faucibus scelerisque
                      eleifend donec pretium. Est pellentesque elit ullamcorper
                      dignissim. Mauris ultrices eros in cursus turpis massa
                      tincidunt dui.
                    </p>
                  </div>
                </div>

                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle5"
                    className="faq-toggle-input"
                  />

                  <label htmlFor="faq-toggle5" className="faq-toggle-label">
                    <h6 style={{ color: "#0d83fd", margin: "0" }}>
                      Tempus quam pellentesque nec nam aliquam sem et tortor?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>
                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Molestie a iaculis at erat pellentesque adipiscing
                      commodo. Dignissim suspendisse in est ante in. Nunc vel
                      risus commodo viverra maecenas accumsan. Sit amet nisl
                      suscipit adipiscing bibendum est. Purus gravida quis
                      blandit turpis cursus in
                    </p>
                  </div>
                </div>

                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle6"
                    className="faq-toggle-input"
                  />

                  <label htmlFor="faq-toggle6" className="faq-toggle-label">
                    <h6 style={{ color: "#0d83fd", margin: "0" }}>
                      Perspiciatis quod quo quos nulla quo illum ullam?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>
                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Enim ea facilis quaerat voluptas quidem et dolorem. Quis
                      et consequatur non sed in suscipit sequi. Distinctio ipsam
                      dolore et.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="call-to-action-2"
        className="call-to-action-2 section dark-background "
      >
        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="col-xl-10">
              <div className="text-center">
                <div
                  className="fs-5 fw-bold"
                  style={{ color: "white", marginTop: "90px" }}
                >
                  Ready to Streamline Your Hiring Process?
                </div>
                <p className="mt-4" style={{ color: "white" }}>
                  Start leveraging HRMWARE skilltester today and experience the
                  difference.It's free,user-friendly, and accesible to identify
                  skilled the candidates.{" "}
                </p>
                <button
                  className="btn btn-secondary ctabtn-2  mt-2"
                  style={{ marginBottom: "90px" }}
                >
                  Get Started For Free
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact section light-background">
        <div className="contact-section container">
          <h2
            className="text-center"
            style={{ marginTop: "130px", lineHeight: "40px" }}
          >
            Contact
          </h2>
        </div>
        <div
          className="contact-section-data-container container "
          style={{ marginTop: "85px" }}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div
            className="row  me-0 ps-3 "
            style={{ justifyContent: "space-between" }}
          >
            <div className="left-section col-lg-5  ">
              <div className="info-box" data-aos="fade-up" data-aos-delay="200">
                <h3 className="mt-4">Contact Info</h3>
                <p>
                  Praesent sapien massa, convallis a pellentesque nec, egestas
                  non nisi. Vestibulum ante ipsum primis.
                </p>

                <div
                  className="info-item d-flex gap-3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon-box ">
                    <IconBrandGoogleMaps stroke={1} size={40} />
                  </div>
                  <div className="content">
                    <h4>Our Location</h4>
                    <p>A108 Adam Street</p>
                    <p>New York, NY 535022</p>
                  </div>
                </div>

                <div
                  className="info-item d-flex gap-3 mb-3"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon-box">
                    <IconPhone stroke={1} size={40} />
                  </div>
                  <div className="content">
                    <h4>Phone Number</h4>
                    <p>+1 5589 55488 55</p>
                    <p>+1 6678 254445 41</p>
                  </div>
                </div>

                <div
                  className="info-item d-flex gap-3"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <div className="icon-box">
                    <IconMail stroke={1} size={40} />
                  </div>
                  <div className="content mb-3">
                    <h4>Email Address</h4>
                    <p>info@example.com</p>
                    <p>contact@example.com</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 "
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <div className="git-container container px-5 ">
                <div className="contact-form">
                  <h3>Get In Touch</h3>
                  <p className="mt-2">
                    Submit Your Details And Our Team Will Contact Within 24
                    Hours.
                  </p>

                  <form action="#" method="post">
                    <div className="row ">
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          name="firstname"
                          className="form-control"
                          placeholder="First Name"
                          required=""
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          name="lastname"
                          className="form-control"
                          placeholder="Last Name"
                          required=""
                        />
                      </div>

                      <div className="col-12 mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email ID"
                          required=""
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <input
                          type="tel"
                          className="form-control"
                          name="phonenumber"
                          placeholder="Enter Your Phone Number"
                        />
                      </div>

                      <div className="col-12">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="4"
                          placeholder="Message"
                          required=""
                        ></textarea>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <button
                          type="submit"
                          className="btn btn-primary contact-button mt-4"
                        >
                          Interested
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="footer-section-container container ">
          <div className="footer-row row  pt-5 pb-4">
            <div className="col-lg-4 col-md-6 footer-about mb-5">
              <span className="sitename fs-3 fw-bold">ilanding</span>
              <div className="footer-contact pt-3">
                <p>A108 Adam Street</p>
                <p>New York, NY 535022</p>
                <p>
                  <span className="fw-bold">Phone:</span> +1 5589 55488 55
                </p>

                <p>
                  <span className="fw-bold">Email:</span> info@example.com
                </p>
              </div>
              <div className="social-logo d-flex gap-2  mt-3">
                <a href="">
                  <IconBrandX stroke={2} size={25} />
                </a>

                <a href="">
                  <IconBrandFacebook stroke={2} size={25} />
                </a>
                <a href="">
                  <IconBrandInstagram stroke={2} size={25} />
                </a>
                <a href="">
                  <IconBrandLinkedin stroke={2} size={25} />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 footer-links  ">
              <h5>Useful Links</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Home</a>{" "}
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links  ">
              <h5>Our Services</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Web Design</a>
                </li>
                <li>
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <a href="#'">Marketing</a>
                </li>
                <li>
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links ">
              <h5>Hic solutasetp</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Molestiae accusamus iure</a>
                </li>
                <li>
                  <a href="#">Excepturi dignissimos</a>
                </li>
                <li>
                  <a href="#">Suscipit distinctio</a>
                </li>
                <li>
                  <a href="#">Dilecta</a>
                </li>
                <li>
                  <a href="#">Sit quas consectetur</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 footer-links  ">
              <h5>Nobis illum</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Ipsam</a>
                </li>
                <li>
                  <a href="#">Laudantium dolorum</a>
                </li>
                <li>
                  <a href="#">Dinera</a>
                </li>
                <li>
                  <a href="#">Trodelas</a>
                </li>
                <li>
                  <a href="#">Flexo</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-container container mt-5 text-center  w-100 ">
          <div className="copyright-row row">
            <p className="copyright">
              © <span>Copyright</span>
              <strong className="sitename px-1">iLanding</strong>{" "}
              <span>All Rights Reserved</span>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Home;
